<template>
	<form @submit.prevent="applyFilter" class="grid grid-cols-5 gap-3 pb-4 mb-4 border-b">
		<div class="col-span-2 xl:col-span-1">
			<DateRangeSelector v-model="dateRangeArray" :clearable="false" />
		</div>
		<div class="col-span-2 xl:col-span-1">
			<sms-select v-model="localFilter.pupilId" label="" class="select-sm leading-4 capitalize w-40" :options="pupilsForFilter"></sms-select>
		</div>
		<div class="col-span-2 xl:col-span-1">
			<sms-select v-model="localFilter.activityId" label="" class="select-sm leading-4 capitalize w-40" :options="activitiesForFilter"></sms-select>
		</div>
		<div class="relative col-span-2 xl:col-span-1">
			<div class="absolute inset-y-0 left-0 pl-3 flex pt-2 md:pt-0 md:items-center pointer-events-none">
				<MagnifyingGlassIcon class="w-5 h-5 text-base-content-light" />
			</div>
			<div class="flex flex-wrap -mx-3">
				<div class="w-full px-3 md:mb-0">
					<sms-input v-model="localFilter.keywords" name="search" minlength="3" type="search" class="input-sm pl-10 w-full" />
				</div>
			</div>
		</div>
		<div class="col-span-2 xl:col-span-1 flex justify-start">
			<button type="submit" class="btn btn-primary btn-sm">{{ $t('Apply') }}</button>
			<button v-show="!observationsStore.filter.isDefault" @click="handleFilterReset" type="button" class="btn btn-ghost btn-sm">{{ $t('Reset') }}</button>
		</div>
	</form>
</template>



<script lang="ts">
import { mapStores } from "pinia";
import { useActivitiesStore } from "@/stores/Activities.store";
import { usePupilsStore } from '@/stores/Pupils.store';

import DateRangeSelector from "./ui/DateRangeSelector.vue";
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import {useObservationsStore} from "@/stores/Observations.store";
import { DateTime, Interval } from 'luxon';
import {FilterParamValue} from "@/utils/FilterDefs.util";

export default {

	props: {},

	components: {
		DateRangeSelector,
		MagnifyingGlassIcon,
	},

	data() {
		return {
			localFilter: null,
		}
	},

	computed: {
		...mapStores(
			usePupilsStore,
			useActivitiesStore,
			useObservationsStore
		),

		dateRangeArray: {
			get(): DateTime[] {
				if(
					this.localFilter.getParamValues('start').length === 0 ||
					this.localFilter.getParamValues('end').length === 0
				) {
					return null;
				} else {

					const start = this.localFilter.getParamValues('start')[0].id;
					const end = this.localFilter.getParamValues('end')[0].id;

					return [
						DateTime.fromISO(start),
						DateTime.fromISO(end)
					];
				}
			},
			set(value: Date[]) {

				this.localFilter.setInterval(Interval.fromDateTimes(
					DateTime.fromJSDate(value[0]).startOf('day'),
					DateTime.fromJSDate(value[1]).endOf('day')
				));

			}
		},

		activitiesForFilter() {
			let list = [];
			list.push({
				value: '',
				label: this.$t('All activities'),
			});
			list.push({
				value: '',
				label: '-----------------',
				disabled: true,
			});

			for (let activity of this.activitiesStore.activities) {
				list.push({
					value: activity.id,
					label: activity.name,
				});
			}
			return list;
		},

		pupilsForFilter() {
			let list = [];
			list.push({
				value: '',
				label: this.$t('All pupils'),
			});
			list.push({
				value: '',
				label: '-----------------',
				disabled: true,
			});

			for (let pupil of this.pupilsStore.pupils) {
				list.push({
					value: pupil.id,
					label: pupil.firstName + ' ' + pupil.lastName,
				});
			}
			return list;
		},
	},

	beforeMount() {
		this.localFilter = this.observationsStore.filter.clone();
		this.localFilter.isDefault = false;
	},

	mounted() {
		this.loadPupils();
		this.loadActivities();
	},

	methods: {
		async loadActivities() {
			await this.activitiesStore.load();
		},

		async loadPupils() {
			await this.pupilsStore.load();
		},

		applyFilter() {
			this.observationsStore.applyFilter(this.localFilter);
		},

		handleFilterReset() {
			this.observationsStore.resetFilter();
			this.localFilter = this.observationsStore.filter.clone();
		},
	},
}

</script>
